import React, { FC } from 'react';
import { graphql } from 'gatsby';
import { FluidObject } from 'gatsby-image';

import IndexCover from '../components/index-cover';
import Layout from '../components/layout';
import SEO from '../components/seo';
import ArticleCard from '../components/article-card';
import ContentSection from '../components/content-section';

interface Props {
  data: {
    allMarkdownRemark: any;
    site: {
      siteMetadata: {
        title: string;
      };
    };
    seoCover: {
      childImageSharp: {
        fixed: any;
      };
    };
    indexCover: {
      childImageSharp: {
        fluid: FluidObject | FluidObject[];
      };
    };
  };
  location: Location;
}

const BlogIndex: FC<Props> = ({ data }) => {
  const posts = data.allMarkdownRemark.edges;

  return (
    <Layout>
      <SEO title="Home" metaImage={data.seoCover.childImageSharp.fixed} />
      <IndexCover fluid={data.indexCover.childImageSharp.fluid} />
      <ContentSection>
        <h2 style={{ marginBottom: '2rem' }}>Hi, I&apos;m JM</h2>
        <p style={{ marginBottom: '2rem' }}>
          I&apos;m Jonmichael, but you can call me &quot;JM&quot;. I&apos;m a
          Software Engineer and dog dad living in Southern California. This is
          my blog where I write about things I know, and things I&apos;m
          learning.
        </p>
      </ContentSection>
      <ContentSection>
        <h3>Recent Posts</h3>
      </ContentSection>
      <ContentSection>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-around',
          }}
        >
          {posts.map(({ node }: any) => (
            <ArticleCard node={node} key={node.fields.slug} />
          ))}
        </div>
      </ContentSection>
      <ContentSection>
        <small>Cover photo by Daniel Probst on Unsplash</small>
      </ContentSection>
    </Layout>
  );
};

export default BlogIndex;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        fields: { draft: { eq: false } }
        frontmatter: { list: { eq: null } }
      }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            cover_photo {
              childImageSharp {
                fixed(width: 300, height: 200) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    }
    seoCover: file(relativePath: { eq: "assets/profile-pic.jpg" }) {
      childImageSharp {
        fixed(height: 1200, width: 1200) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    indexCover: file(
      relativePath: { eq: "photos/daniel-probst-ok2OISDAu3I-unsplash.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 450) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`;
