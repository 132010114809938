import React from 'react';
import Image, { FluidObject } from 'gatsby-image';

interface Props {
  fluid: FluidObject | FluidObject[];
}

const IndexCover = ({ fluid }: Props) => (
  <Image
    style={{ maxHeight: '450px', marginBottom: '2rem' }}
    imgStyle={{ maxHeight: '450px' }}
    fluid={fluid}
  />
);

export default IndexCover;
